import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { Section } from '../../components/common/';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
`;

const Search = ({ data }) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Grid>
        <Section
          lightBackground
          padding="1.5rem"
          style={{ position: 'sticky' }}
        >
          <h4>Base Website Docs</h4>
          <hr />
          <ul>
            <li>
              <Link to="/docs/">Overview</Link>
            </li>
            <li>
              <Link to="/docs/content">Content Management</Link>
            </li>
            <li>
              <Link to="/docs/search/">Site search</Link>
            </li>
            <li>
              <Link to="/docs/developers">Developers</Link>
            </li>
          </ul>
          <hr />
          <h4>Components</h4>
          <ul>
            <li>
              <Link to="/docs/components">What is a component?</Link>
            </li>
            {data.allComponentMetadata.edges.map(({ node: doc }) => (
              <li key={doc.id}>
                <Link to={`/docs/${doc.displayName}/`}>{doc.displayName}</Link>
              </li>
            ))}
          </ul>
        </Section>

        <Section>
          <h1>Site Search</h1>
          <p>
            Site search on the website is provided by Algolia. This solution was
            chosen due to the fact that the site uses serverless technology and
            need to rely on some external library for indexing and searching
            data.
          </p>
          <p>
            Algolia also provides data on searches to we can figure out demand
            for content that does and does not exist
          </p>
          <p>Access to algolia is via the webmaster@nomadsworld.com address</p>
        </Section>
      </Grid>
    </>
  );
};

export default Search;

export const pageQuery = graphql`
  query {
    allComponentMetadata {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
`;
